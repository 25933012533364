<template>
  <div class="page-wrap">
    <div class="session-form-hold">
      <base-card>
        <v-card-text class="text-center">
          <div class="py-1">
            <h5 class="font-weight-bold">Renew Subscription</h5>
            <p class="text--disabled font-weight-medium">
              Please check with the plan subscription with admin
            </p>
          </div>

          <v-autocomplete
            v-model="selectedCompany"
            label="Company"
            item-text="company_name"
            item-value="company_id"
            :items="companyList"
            :error-messages="selectedCompanyErrors"
            required
            @input="$v.selectedCompany.$touch()"
            @blur="$v.selectedCompany.$touch()"
          ></v-autocomplete>

          <v-select
            v-model="selectedPlan"
            :items="items"
            label="Subscription Plan"
            :item-text="getFieldText"
            return-object
            :error-messages="selectedPlanErrors"
            required
            @input="$v.selectedPlan.$touch()"
            @blur="$v.selectedPlan.$touch()"
          ></v-select>

          <v-btn class="mb-4" block @click="renew" color="primary">Renew</v-btn>
          <!-- <div class="">
            Already have an account ?
            <v-btn text small color="primary" to="/app/sessions/login"
              >Sign In</v-btn
            >
          </div> -->
        </v-card-text>

        <!-- error password snackbar  -->
        <v-snackbar v-model="snackbar" top :color="sColor">
          {{ message }}
          <template v-slot:action="{ attrs }">
            <v-btn color="" text v-bind="attrs" @click="snackbar = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
      </base-card>
    </div>
  </div>
</template>
  <script>
import { BASEURL, BASESYNCURL } from "@/api/baseurl";
import axios from "axios";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  name: "renew",
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "renew",
  },
  mixins: [validationMixin],

  validations: {
    selectedPlan: { required },
    selectedCompany: { required },
  },

  data() {
    return {
      domain: BASEURL,
      syncDomain: BASESYNCURL,
      show: false,
      checkbox: false,
      email: "",
      message: "",
      password: "",
      name: "",
      bName: "",
      selectedCompany: "",
      loading: false,
      snackbar: false,
      sColor: "",
      emailExist: "",
      items: [],
      companyList: [],
      selectedPlan: "",
    };
  },
  created() {
    this.getAllCompany();
    this.getAllSubscriptionPlan();
  },

  computed: {
    selectedPlanErrors() {
      const errors = [];
      if (!this.$v.selectedPlan.$dirty) return errors;
      !this.$v.selectedPlan.required &&
        errors.push("Subscription Plan is required.");
      return errors;
    },
    selectedCompanyErrors() {
      const errors = [];
      if (!this.$v.selectedCompany.$dirty) return errors;
      !this.$v.selectedCompany.required && errors.push("Company is required.");
      return errors;
    },
  },
  methods: {
    getAllCompany() {
      const params = new URLSearchParams();
      params.append("read", "done");
      axios({
        method: "post",
        url: this.domain + "/company/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.companyList = response.data.company;
          } else {
            this.companyList = [];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getCompanyBranch(company_id) {
      const params = new URLSearchParams();
      params.append("getCompanyBranch", "done");
      params.append("company_id", company_id);

      axios({
        method: "post",
        url: this.domain + "/branch/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            var branchList = response.data.branch;
            for (let i = 0; i < branchList.length; i++) {
                this.sendNotication(branchList[i].branch_id);
              }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getAllSubscriptionPlan() {
      const params = new URLSearchParams();
      params.append("readAllSubscription", "done");
      axios({
        method: "post",
        url: this.domain + "/subscription/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.items = response.data.subscription;
          } else {
            this.items = [];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getFieldText(item) {
      return `${item.subscription_package} - RM${item.subscription_fee} - ${item.duration} months - ${item.branch_amount} branch`;
    },

    renew() {
      var moment = require("moment");
      var startDate = moment().format("YYYY-MM-DD");
      var endDate = moment(startDate)
        .add(this.selectedPlan.duration, "M")
        .format("YYYY-MM-DD");

      this.$v.$touch();
      if (!this.$v.$invalid) {
        const params = new URLSearchParams();
        params.append("renew_subscription", "done");
        params.append("company_id", this.selectedCompany);
        params.append("subscription_plan_id", this.selectedPlan.id);
        params.append("subscription_package", this.selectedPlan.subscription_package);
        params.append("subscription_fee", this.selectedPlan.subscription_fee);
        params.append("duration", this.selectedPlan.duration);
        params.append("branch_amount", this.selectedPlan.branch_amount);
        params.append("start_date", startDate);
        params.append("end_date", endDate);
        axios({
          method: "post",
          url: this.domain + "/subscription/index.php",
          data: params,
        })
          .then((response) => {
            console.log(response);
            if (response.data.status == "1") {
              this.snackbar = true;
              this.sColor = "success";
              this.message = "Successfully renew account.";
              this.getCompanyBranch(this.selectedCompany);
              this.selectedCompany = "";
              this.selectedPlan = "";
              this.$v.$reset()

            } else {
              this.snackbar = true;
              this.sColor = "danger";
              this.message = "Something went wrong. Please try again";
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    sendNotication(branch_id) {
      const params = new URLSearchParams();
      params.append("send_notification", "done");
      params.append("branch_id", branch_id);
      params.append("type", "1");

      axios({
        method: "post",
        url: this.syncDomain + "/sync/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            console.log("Send Notification successfully");
          } else {
            console.log("Send Notification fail");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
  <style lang="scss" scoped>
.page-wrap {
  background-color: #242939 !important;
  display: flex;
  align-items: center;
  padding: 40px 1rem;
  height: 100%;
  min-height: 100vh;
}
.session-form-hold {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}
</style>
  